<template>

  <!-- Startup Costs -->
  <div id="story-mode-startup">

    <!-- Main Navigation -->
    <main-navigation />
    <!-- /Main Navigation -->

    <!-- Container -->
    <div class="container-1200">
      <loader v-if="!loaded.startupCosts" />

      <!-- Content -->
      <div
        v-if="loaded.startupCosts"
        class="content"
        :class="{ 'position-relative': isStartupCostsCompleted }"
      >
        <v-app>
          <!-- Page Header -->
          <header class="page-header">

            <!-- Dashboard Header -->
            <dashboard-header
              :class="{ 'stamp': isStartupCostsCompleted }"
              :title="$t('steps.setUp.items.startupCosts')"
              :guide-visible="guideVisible"
              :completed-step-name="'startupCostsCompleted'"
              hide-quick-tour-button
              @toggle-guide="onGuideExampleVisible"
              @open-comp-dialog="openedDialogProcessCompleted"
              @open-info="guideVisible = true"
              @open-ai-buddy-drawer="aiBuddyDrawerVisible = true"
            />
            <!-- /Dashboard Header -->

            <!-- Divider -->
            <hr>
          <!-- /Divider -->

          </header>
        <!-- /Page Header -->
        </v-app>

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Charts And Table Placeholders -->
        <div v-if="isStartupCostsEmpty" class="table-view">
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div class="switcher-inner-wrapper">
                <div class="placeholder breakdown" />
              </div>
            </el-tab-pane>
            <!-- /Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div class="switcher-inner-wrapper">
                <div class="placeholder timeline" />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div class="switcher-inner-wrapper">
                <div class="placeholder table" />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
        </div>
        <!-- /Charts And Table Placeholders -->

        <!-- Charts And Table Filled -->
        <div v-if="!isStartupCostsEmpty" class="table-view filled">
          <el-tabs v-model="activeName">

            <!-- Breakdown -->
            <el-tab-pane :label="$t('breakdown')" name="first">
              <div v-if="activeName === 'first'" class="bg-white">
                <distribution-of-startup-costs
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- Breakdown -->

            <!-- Timeline -->
            <el-tab-pane :label="$t('timeline')" name="second">
              <div v-if="activeName === 'second'" class="bg-white">
                <startup-costs-over-time
                  class="pt-4"
                  chart-animations
                />
              </div>
            </el-tab-pane>
            <!-- /Timeline -->

            <!-- Table -->
            <el-tab-pane :label="$t('table')" name="third">
              <div v-if="activeName === 'third'" class="switcher-inner-wrapper">
                <startup-costs-table
                  class="pt-4"
                  :table-rows-to-expand="tableRowsToExpand"
                  :table-adjustment="tableAdjustment"
                />
              </div>
            </el-tab-pane>
            <!-- /Table -->

          </el-tabs>
        </div>
        <!-- /Charts And Table Filled -->

        <v-app>
          <!-- Header -->
          <business-guide-header>
            <template #right>
              <!-- Erase button -->
              <erase-icon-button
                v-if="removeButtonVisible"
                @click="onEraseFromTemplate"
              />
              <!-- Erase button -->
              <!-- Search Input-->
              <business-guide-search
                v-if="!isStartupCostsEmpty"
                v-model="searchValue"
                class="mx-1"
              />
              <!-- Search -->
              <!-- View Switcher -->
              <view-switcher
                v-if="!isStartupCostsEmpty"
                :active-view="$store.state.itemsView.startupCostsView"
                @activate-view="onActivateView"
              />
              <!-- /View Switcher -->
            </template>
          </business-guide-header>
          <!-- /Header -->
        </v-app>

        <!-- List Wrapper -->
        <div
          class="list-wrapper"
          :class="{ 'active': $store.state.itemsView.startupCostsView === 'list' }"
        >
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isStartupCostsCompleted"
              @click="checkStartupCosts"
            />
            <!-- /Completed Overlay -->
            <!-- Add Startup Cost -->
            <el-col :md="12" class="d-flex mb-2 position-relative">
              <add-new-card
                class="width-xl-20 py-2 py-md-0"
                :title="$tc('addStartupCost')"
                :disabled="!canEdit || isStartupCostsCompleted"
                horizontal
                @click="onAddStartupCost"
              />
            </el-col>
            <!-- /Add Startup Cost -->

            <!-- Placeholder 1 -->
            <el-col v-if="isStartupCostsEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 1 -->

            <!-- Placeholder 2 -->
            <el-col v-if="isStartupCostsEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder blue">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 2 -->

            <!-- Placeholder 3 -->
            <el-col v-if="isStartupCostsEmpty && $breakpoint.mdAndUp" :md="12">
              <div class="placeholder yellow">
                <div class="inner" />
              </div>
            </el-col>
            <!-- /Placeholder 3 -->

            <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
            <tutorial-tooltip
              v-if="$store.state.user.tutorial.mainBottomBarCompleted === false && 1 === 2"
              :title="$t('pages.businessGuide.startUpCosts.addCustomerYouWantToTarget')"
              :text="$t('pages.businessGuide.thisIsStarOfYourShow')"
              :tutorial-name="'startupCostsCompleted'"
            />
            <!-- /Tutorial Tooltip -->

            <!-- List Items -->
            <el-col
              v-for="startupCost in startupCostsToShowList"
              :key="startupCost.id"
              class="d-flex mb-2"
              :md="12"
            >
              <div class="d-flex w-100" @click="checkStartupCosts">
                <set-up-and-projections-card
                  class="w-100"
                  :entity="startupCost"
                  :name="startupCost.name"
                  :date="getFormattedDate(startupCost.whenWillItOccur)"
                  :amount="getFormattedAmount(startupCost.amount)"
                  :frequency="startupCost.oneTime ? $t('oneTime') : $t('installments')"
                  :type="startupCost.typeGroup"
                  :disabled="isStartupCostsCompleted"
                  view="list"
                  @edit="onEditStartupCost"
                  @copy="onCopyStartupCost"
                  @delete="onDeleteStartupCost"
                />
                <!--                  :copy-disabled="startupCost.type === startupCost.typeGroup"-->
              </div>
            </el-col>
            <!-- /List Items -->
          </el-row>
          <!-- Show All -->
          <el-row v-if="idea.storyMode.setUp.startupCosts.startupCosts.length > 7">
            <el-col>
              <show-all-toggle
                :show="showAllStartupCostsList"
                @click="showAllStartupCostsList = !showAllStartupCostsList"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /List Wrapper -->

        <!-- Grid Wrapper -->
        <div
          class="grid-wrapper"
          :class="{ 'active': $store.state.itemsView.startupCostsView === 'grid' }"
        >
          <el-row :gutter="20" class="d-flex flex-wrap">
            <!-- Completed Overlay -->
            <completed-overlay
              v-if="!canEdit || isStartupCostsCompleted"
              @click="checkStartupCosts"
            />
            <!-- /Completed Overlay -->
            <!-- Add Startup Cost -->
            <el-col :md="8" :xl="6" class="d-flex mb-3 width-lg-20 position-relative mb-4">
              <add-new-card
                class="width-xl-20"
                :title="$tc('addStartupCost')"
                :disabled="!canEdit || isStartupCostsCompleted"
                @click="onAddStartupCost"
              />
            </el-col>
            <!-- /Add Startup Cost -->

            <!-- Grid Items -->
            <el-col
              v-for="startupCost in startupCostsToShowGrid"
              :key="startupCost.id"
              class="d-flex width-lg-20 mb-4"
              :md="8"
              :xl="6"
            >
              <div class="d-flex w-100" @click="checkStartupCosts">
                <set-up-and-projections-card
                  class="w-100"
                  :entity="startupCost"
                  :name="startupCost.name"
                  :date="getFormattedDate(startupCost.whenWillItOccur)"
                  :amount="getFormattedAmount(startupCost.amount)"
                  :frequency="startupCost.oneTime ? $t('oneTime') : $t('installments')"
                  :type="startupCost.typeGroup"
                  :disabled="isStartupCostsCompleted"
                  @edit="onEditStartupCost"
                  @copy="onCopyStartupCost"
                  @delete="onDeleteStartupCost"
                />
                <!--                  :copy-disabled="startupCost.type === startupCost.typeGroup"-->
              </div>
            </el-col>
          <!-- /Grid Items -->
          </el-row>

          <!-- Show All -->
          <el-row v-if="idea.storyMode.setUp.startupCosts.startupCosts.length > 4">
            <el-col>
              <show-all-toggle
                :show="showAllStartupCostsGrid"
                @click="showAllStartupCostsGrid = !showAllStartupCostsGrid"
              />
            </el-col>
          </el-row>
          <!-- /Show All -->
        </div>
        <!-- /Grid Wrapper -->

      </div>
      <!-- /Content -->

      <!-- Add More Details -->
      <add-more-details
        :category="'setup'"
        :step="'startup-cost'"
        :concept-name="'startupCostMoreDetails'"
        :completed-step-name="'startupCostsCompleted'"
      />
      <!-- /Add More Details -->

    </div>
    <!-- /Container -->

    <!-- Main Bottom Bar -->
    <main-bottom-bar
      :back="backNavigation"
      :next="nextNavigation"
      :completed-step-name="'startupCostsCompleted'"
      :step-filled="idea.storyMode.setUp.startupCosts.startupCosts.length > 0"
      @complete-step="onCompleteStep"
    />
    <!-- /Main Bottom Bar -->

    <!-- Info Guide Drawer -->
    <info-guide-drawer
      type="startupCostsExamples"
      :visible="guideVisible"
      @close="guideVisible = false"
    />
    <!-- /Info Guide Drawer -->

    <!-- Ai Buddy Drawer -->
    <ai-buddy-drawer
      :visible="aiBuddyDrawerVisible"
      type="startup-cost"
      @close="aiBuddyDrawerVisible = false"
    />
    <!-- /Ai Buddy Drawer -->

    <!-- Dialog Add Startup Cost -->
    <dialog-add-startup-cost
      v-if="loaded.startupCosts || dialogAddStartupCostVisible"
      :dialog-add-startup-cost-visible="dialogAddStartupCostVisible"
      :startup-cost-action="startupCostAction"
      @close-dialog-add-startup-cost="onCloseDialogAddStartupCost"
      @close-dialog-on-button="dialogAddStartupCostVisible = false"
      @expand-table-rows="populateTableRowsToExpand"
      @on-update-startup-cost-view="getStartupCosts"
    />
    <!-- Dialog Add Startup Cost -->

    <!-- Dialog Process Completed -->
    <dialog-process-completed
      :dialog-process-completed-visible="dialogProcessCompletedVisible"
      @close-dialog-process-completed="onCloseDialogProcessCompleted"
    />
    <!-- /Dialog Process Completed -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Startup Costs -->

</template>

<script>
import AddMoreDetails from '@/views/Home/StoryMode/Components/AddMoreDetails'
import AddNewCard from '@/components/_v2/AddNewCard'
import AiBuddyDrawer from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddyDrawer.vue'
import BusinessGuideHeader from '@/components/_v2/Common/BusinessGuideHeader'
import CompletedOverlay from '@/views/Home/StoryMode/Components/CompletedOverlay'
import DashboardHeader from '@/views/Home/StoryMode/Components/DashboardHeader'
import DialogAddStartupCost from './Dialogs/DialogAddStartupCost'
import DialogProcessCompleted from '@/views/Home/StoryMode/Components/Dialogs/DialogProcessCompleted'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import DistributionOfStartupCosts from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/StartupCost/DistributionOfStartupCosts'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import Loader from '@/components/Loader'
import MainBottomBar from '@/views/Home/StoryMode/Components/MainBottomBar/MainBottomBar'
import MainNavigation from '@/views/Home/StoryMode/Components/MainNavigation/MainNavigation'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinGuide from '@/mixins/guide'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinProcessCompleted from '@/mixins/processCompleted'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'
import ShowAllToggle from '@/views/Home/StoryMode/Components/ShowAllToggle'
import StartupCostsOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/StartupCost/StartupCostsOverTime'
import StartupCostsTable from './Tables/StartupCostsTable'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'
import ViewSwitcher from '@/components/_v2/ViewSwitcher'

import { mapActions, mapGetters, mapState } from 'vuex'
import BusinessGuideSearch from '@/views/Home/Components/BusinessGuideSearch.vue'
import InfoGuideDrawer from '@/views/Home/StoryMode/Components/Example/InfoGuideDrawer.vue'

export default {
  name: 'StartupCosts',

  components: {
    AiBuddyDrawer,
    InfoGuideDrawer,
    BusinessGuideSearch,
    AddMoreDetails,
    AddNewCard,
    BusinessGuideHeader,
    CompletedOverlay,
    DashboardHeader,
    DialogAddStartupCost,
    DialogProcessCompleted,
    DialogRemoveExamples,
    DistributionOfStartupCosts,
    EraseIconButton,
    Loader,
    MainBottomBar,
    MainNavigation,
    SetUpAndProjectionsCard,
    ShowAllToggle,
    StartupCostsOverTime,
    StartupCostsTable,
    TutorialTooltip,
    ViewSwitcher
  },

  mixins: [
    MixinApiCalculations,
    MixinCurrencies,
    MixinProcessCompleted,
    MixinGuide,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinEraseTemplate
  ],

  data () {
    return {
      aiBuddyDrawerVisible: false,
      searchValue: '',
      removeExamplesLoading: false,
      dialogRemoveExamplesVisible: false,
      activeName: 'first',
      dialogAddStartupCostVisible: false,
      guideVisible: false,
      startupCostAction: null,
      showAllStartupCostsGrid: false,
      showAllStartupCostsList: false,
      dialogProcessCompletedVisible: false,
      updateCharts: true,
      tableRowsToExpand: [],
      tableAdjustment: {
        journalPage: false,
        period: 'monthly',
        tableFirstColumnWidth: 250,
        columnWidth: 118
      }
    }
  },

  computed: {
    ...mapState(['idea']),

    ...mapGetters('idea', {
      startupCosts: 'getStartupCosts',
      getStepIsCompleted: 'getStepIsCompleted',
      getHasItemsFromTemplate: 'getHasItemsFromTemplate'
    }),

    isStartupCostsEmpty () {
      return !this.startupCosts.length
    },

    isStartupCostsCompleted () {
      return this.getStepIsCompleted('startupCostsCompleted')
    },

    startupCostsToShowGrid () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterStartupCosts
      }

      return this.showAllStartupCostsGrid === true
        ? this.startupCosts
        : this.startupCosts.slice(0, 4)
    },

    startupCostsToShowList () {
      if (this.searchValue !== '' && this.searchValue) {
        return this.filterStartupCosts
      }

      return this.showAllStartupCostsList === true
        ? this.startupCosts
        : this.startupCosts.slice(0, 7)
    },

    filterStartupCosts () {
      return this.startupCosts.filter(item => item.name.toUpperCase().includes(this.searchValue.toUpperCase()))
    },

    ideaStartingFrom () {
      return this.idea.ideaSettings.startingFrom
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getStartupCosts')
    },

    backNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.marketPotential'),
        route: 'business-guide-research-market-potential'
      }
    },

    nextNavigation () {
      return {
        title: this.$t('pages.businessGuide.navigation.financing'),
        route: 'business-guide-set-up-financing'
      }
    }
  },

  watch: {
    ideaStartingFrom () {
      this.getStartupCosts()
    }
  },

  async created () {
    await this.getStartupCosts()
    await this.getStartupCostsMoreDetails()
    this.openGuide('startupCostGuide')
  },

  methods: {
    ...mapActions('idea', [
      'deleteIdeaExamples',
      'deleteStoryModeStartupCosts',
      'getStartupCostsMoreDetails'
    ]),

    checkStartupCosts () {
      if (!this.canEdit) return
      if (this.isStartupCostsCompleted) {
        this.dialogProcessCompletedVisible = true
      }
    },

    onAddStartupCost () {
      if (!this.canEdit) return
      this.dialogAddStartupCostVisible = true
    },

    onCloseDialogAddStartupCost () {
      this.dialogAddStartupCostVisible = false
      this.startupCostAction = null

      // Workaround to update computed property startupCostsToShowGrid & startupCostsToShowList
      this.updateStartupCostsView()
    },

    updateStartupCostsView () {
      this.showAllStartupCostsList = !this.showAllStartupCostsList
      this.showAllStartupCostsList = !this.showAllStartupCostsList
      this.showAllStartupCostsGrid = !this.showAllStartupCostsGrid
      this.showAllStartupCostsGrid = !this.showAllStartupCostsGrid
      this.updateCharts = !this.updateCharts
    },

    onActivateView (view) {
      this.$store.commit('itemsView/setStartupCostsView', view)
    },

    onEditStartupCost (startupCost) {
      if (!this.canEdit) return
      if (this.isStartupCostsCompleted) {
        this.dialogProcessCompletedVisible = true

        return
      }
      this.startupCostAction = Object.assign({}, startupCost)
      this.dialogAddStartupCostVisible = true
    },

    onCopyStartupCost (startupCost) {
      this.startupCostAction = Object.assign({}, startupCost)
      this.startupCostAction.id = 0
      this.dialogAddStartupCostVisible = true
    },

    onDeleteStartupCost (startupCost) {
      this.loaded.startupCosts = false
      this.deleteStoryModeStartupCosts(startupCost.id)
        .then(_ => {
          this.getStartupCosts()
        }).catch(_ => {
          this.loaded.startupCosts = true
        })

      // Return to list view, if there are no startupCosts
      if (this.idea.storyMode.setUp.startupCosts.startupCosts.length === 0) {
        this.onActivateView('list')
      }

      this.updateStartupCostsView()
    },

    onGuideExampleVisible () {
      this.guideVisible = !this.guideVisible
    },

    onEraseFromTemplate () {
      if (this.isStartupCostsCompleted) {
        this.openedDialogProcessCompleted()

        return
      }
      this.dialogRemoveExamplesVisible = true
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    populateTableRowsToExpand (tableRowsToExpand) {
      this.tableRowsToExpand = tableRowsToExpand
    },

    deleteExamples () {
      this.removeExamplesLoading = true
      this.deleteIdeaExamples('startup-costs')
        .then(response => {
          const { type, ids } = response
          this.deleteFromState({ type, ids })
          this.getStartupCosts()
          this.dialogRemoveExamplesVisible = false
          this.removeExamplesLoading = false
        }).catch(_ => { this.removeExamplesLoading = false })
    }
  }
}
</script>

<style scoped>
  #story-mode-startup {
    margin-left: 0;
  }
</style>
