<template>
  <chart-component>
    <!-- Title -->
    <template #title>
      {{ $t('charts.distributionOfStartupCosts') }}
    </template>
    <!-- /Title -->
    <!-- Breakdown Total Switcher -->
    <template #switcher>
      <breakdown-total-switcher
        class="switcher"
        @change="showTotals = $event"
      />
    </template>
    <!-- /Breakdown Total Switcher -->
    <!-- Chart -->
    <template #chart>
      <distribution-of-startup-costs-chart
        hide-title
        :show-totals="showTotals"
        :chart-animations="chartAnimations"
      />
    </template>
    <!-- /Chart -->
    <!-- Right side -->
    <template #right-side>
      <chart-component-right-side>
        <total-amount
          class="mb-3"
          :amount="totalAmount"
          :text="$t('charts.totalStartupCosts')"
        />
        <div>
          <p class="m-0 text-small mb-4">
            {{ $t('charts.topThreeCosts') }}
          </p>
          <template v-for="(cost, index) in topCosts">
            <year-info
              :key="index"
              class="mb-3"
              :avatar-text="index + 1"
              :text="cost.name"
              circle
              :money="cost.amount"
            />
          </template>
        </div>
      </chart-component-right-side>
    </template>
  </chart-component>
</template>

<script>
import BreakdownTotalSwitcher from '@/components/Charts/Breakdown/BreakdownTotalSwitcher'
import ChartComponent from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponent'
import ChartComponentRightSide from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponentRightSide'
import DistributionOfStartupCostsChart from '@/views/Home/StoryMode/SetUp/StartupCosts/Charts/DistributionOfStartupCostsChart'
import TotalAmount from '@/views/Home/StoryMode/FinancialPlan/Components/TotalAmount'
import YearInfo from '@/views/Home/StoryMode/FinancialPlan/Components/YearInfo'

export default {
  name: 'DistributionOfStartupCosts',

  components: {
    ChartComponentRightSide,
    DistributionOfStartupCostsChart,
    ChartComponent,
    BreakdownTotalSwitcher,
    TotalAmount,
    YearInfo
  },

  props: {
    chartAnimations: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      showTotals: false
    }
  },

  computed: {
    totalAmount () {
      return this.$store.state.idea.storyMode.setUp.startupCosts.breakdown.totalCosts
    },

    topCosts () {
      return this.$store.state.idea.storyMode.setUp.startupCosts.breakdown.topCosts
    }
  }
}
</script>
